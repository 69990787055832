import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import scroll from 'scroll';
import { Scrollbars } from 'rc-scrollbars';

import { getSearch } from '../../api';
import { regexpRef } from '../../utils/tools';

import ResultCard from '../ResultCard';
import Button from '../UI/Button';
import SROnly from '../UI/SROnly';

const keys = {
  UP: 38,
  DOWN: 40,
  ESC: 27
};

const getTypes = (lang) => {
  if (lang === 'fr') {
    return {
      sale: 'vente',
      rental: 'location'
    };
  }

  return {
    sale: 'sale',
    rental: 'rental'
  };
};

const TYPES = {
  sale: 'sale',
  rental: 'rental'
};

const buildUrl = (lang, type, citySlug) => {
  const langPrefix = (lang === 'en' || lang === 'fr') ? '' : `/${lang}`;

  return `${langPrefix}/${getTypes(lang)[type]}/${citySlug}/`;
};

const Thumb = ({
  style = {},
  ...props
}) => (
  <div
    style={{
      ...style,
      backgroundColor: '#808080',
      width: 4,
      borderRadius: 2,
      cursor: 'pointer'
    }}
    {...props}
  />
);

Thumb.propTypes = {
  style: PropTypes.object
};

const initialState = {
  type: TYPES.sale,
  query: '',
  house: null,
  fetching: false,
  opened: false,
  selected: 0,
  scrollable: false
};

const SearchDesktop = ({
  lang,
  sale,
  rental,
  translations,
  placeholder
}) => {
  const optionsRef = useRef();
  const searchRef = useRef();
  const initialRender = useRef(true);

  const [state, setState] = useState({ ...initialState });

  const items = useMemo(() => {
    const { query, type } = state;
    const elements = (type === TYPES.sale) ? sale : rental;

    if (!query) {
      return elements;
    }

    return elements.filter((city) => {
      const name = (city.name || '').toLowerCase();
      return name.indexOf(query.toLowerCase()) !== -1;
    });
  }, [
    rental,
    sale,
    state
  ]);

  const matched = useCallback((value) => (
    value.toLowerCase().match(regexpRef)
  ), []);

  const openResults = useCallback(() => setState((prevState) => {
    if (prevState.opened) {
      return prevState;
    }

    return {
      ...prevState,
      opened: true,
      selected: 0
    };
  }), []);

  const closeResults = useCallback(() => setState((prevState) => {
    if (!prevState.opened) {
      return prevState;
    }

    return {
      ...prevState,
      opened: false,
      selected: null
    };
  }), []);

  const toggleResults = useCallback((value) => {
    const opened = (typeof value === 'boolean') ? value : !state.opened;

    if (opened) {
      openResults();
    } else {
      closeResults();
    }
  }, [
    state,
    closeResults,
    openResults
  ]);

  const onOutsideClick = useCallback(({ target }) => {
    if (!searchRef.current.contains(target)) {
      setState((prevState) => ({
        ...prevState,
        opened: false
      }));
    }
  }, []);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    const {
      house,
      selected,
      type,
      opened
    } = state;

    if (house) {
      window.location.assign(house.url);
    } else if (items[selected] && opened) {
      window.location.assign(buildUrl(lang, type, items[selected].slug));
    }
  }, [
    items,
    lang,
    state
  ]);

  const onChange = useCallback(({ target }) => setState((prevState) => ({
    ...prevState,
    query: target.value,
    selected: 0,
    house: !matched(target.value) ? null : prevState.house
  })), [
    matched
  ]);

  const onWindowKeyDown = useCallback((event) => {
    const { keyCode } = event;

    if (keyCode === keys.ESC) {
      closeResults();
      return;
    }

    if (keyCode === keys.UP) {
      event.stopPropagation();
      event.preventDefault();

      setState((prevState) => ({
        ...prevState,
        selected: (prevState.selected === 0)
          ? 0
          : prevState.selected - 1,
        scrollable: true
      }));
    }

    if (keyCode === keys.DOWN) {
      event.stopPropagation();
      event.preventDefault();

      setState((prevState) => ({
        ...prevState,
        selected: (prevState.selected === items.length - 1)
          ? items.length - 1
          : prevState.selected + 1,
        scrollable: true
      }));
    }
  }, [
    items,
    closeResults
  ]);

  const onMouseMove = useCallback((index) => setState((prevState) => {
    if (prevState.selected === index) {
      return prevState;
    }

    return {
      ...prevState,
      selected: index
    };
  }), []);

  const onTypeChange = useCallback((type) => setState((prevState) => ({
    ...prevState,
    type,
    selected: 0
  })), []);

  useEffect(() => {
    const {
      opened,
      selected,
      scrollable,
      house
    } = state;

    if (!opened || !scrollable || (typeof selected !== 'number') || house) {
      return;
    }

    const elements = optionsRef.current.view.querySelectorAll('a');

    if (elements[selected]) {
      const height = elements[selected].clientHeight;
      const offset = elements[selected].offsetTop - height * 2;
      scroll.top(optionsRef.current.view, offset, { duration: 100 });
    }

    setState((prevState) => ({
      ...prevState,
      scrollable: false
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.selected,
    state.opened,
    state.scrollable
  ]);

  useEffect(() => {
    if (state.opened) {
      scroll.top(optionsRef.current.view);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const parsedQuery = state.query.replace(/\D+/, '');

    setState((prevState) => ({
      ...prevState,
      fetching: true
    }));

    const queryParams = { q: parsedQuery, lang };
    getSearch(queryParams)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          fetching: false
        }));

        if (response.data?.result) {
          setState((prevState) => ({
            ...prevState,
            house: response.data.result
          }));
        }
      })
      .catch(() => {
        // TODO: ignore
      });
  }, [
    lang,
    state.query
  ]);

  useEffect(() => {
    if (state.opened) {
      window.addEventListener('keydown', onWindowKeyDown);
    }

    return () => {
      if (state.opened) {
        window.removeEventListener('keydown', onWindowKeyDown);
      }
    };
  }, [
    state.opened,
    onWindowKeyDown
  ]);

  useEffect(() => {
    document.addEventListener('click', onOutsideClick);

    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, [
    onOutsideClick
  ]);

  const {
    type,
    query,
    selected,
    house,
    opened,
    fetching
  } = state;

  const classContainer = cn('search-widget', {
    'search-widget-opened': !!opened,
    'search-widget-error': !fetching && matched(query) && !house
  });

  return (
    <div
      className={classContainer}
      ref={searchRef}
    >
      <div className="search-widget-panel">
        <div className="search-widget-type">
          <Button
            aria-pressed={String(type === TYPES.sale)}
            className={cn({ active: type === TYPES.sale })}
            onClick={() => onTypeChange(TYPES.sale)}
          >
            {translations.sale}
          </Button>

          <Button
            aria-pressed={String(type === TYPES.rental)}
            className={cn({ active: type === TYPES.rental })}
            onClick={() => onTypeChange(TYPES.rental)}
          >
            {translations.rental}
          </Button>
        </div>

        <form
          className="search-widget-form"
          onSubmit={onSubmit}
        >
          <SROnly
            component="label"
            htmlFor="search_desktop"
          >
            {placeholder}
          </SROnly>

          <input
            autoComplete="off"
            className="search-widget-input"
            placeholder={placeholder}
            id="search_desktop"
            name="search_desktop"
            type="text"
            value={query}
            onChange={onChange}
            onClick={openResults}
            onFocus={openResults}
          />

          <Button
            className="search-widget-toggle"
            onClick={toggleResults}
          />

          <Button
            className="search-widget-button"
            type="submit"
          />
        </form>
      </div>

      {(opened && !house) && (
        <div className="search-widget-results">
          <Scrollbars
            ref={optionsRef}
            autoHeight
            autoHeightMax={210}
            renderThumbVertical={Thumb}
          >
            {items.map((item, index) => (
              <a
                key={item.id}
                className={cn('search-widget-results-item', { selected: selected === index })}
                href={buildUrl(lang, type, item.slug)}
                onMouseMove={() => onMouseMove(index)}
              >
                {item.name}
              </a>
            ))}
          </Scrollbars>
        </div>
      )}

      {(opened && !!house) && (
        <div className="search-widget-results results-card">
          <ResultCard house={house} />
        </div>
      )}
    </div>
  );
};

SearchDesktop.propTypes = {
  lang: PropTypes.string.isRequired,
  sale: PropTypes.array.isRequired,
  rental: PropTypes.array.isRequired,
  translations: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default memo(SearchDesktop);
